<template>
  <div class="create-meeting fs24 fc666">
    <!-- 案主信息 -->
    <div
      v-if="isReplace"
      @click="goDetail(info.sidongInfo.id)"
      class="caseOwner bgfff mb20"
    >
      <div class="dfr">
        <!-- 头像 -->
        <div class="item-portrait-box">
          <!-- 用户头像 -->
          <img
            v-if="info.sidongInfo.avatar"
            :src="info.sidongInfo.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <!-- 默认头像 -->
          <img
            v-else
            src="../assets/images/default-portrait.png"
            alt=""
            class="w100 h100"
          />
          <!-- 徽章 -->
          <div v-if="info.sidongInfo.is_major_auth" class="item-badge-box">
            <img
              src="../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- 信息 -->
        <div class="item-message">
          <div class="dfc">
            <div class="dfr cross-center">
              <!-- 姓名 -->
              <div class="fs32 fw600 fc333">
                {{ info.sidongInfo.name }}
              </div>
              <!-- 职位 -->
              <div class="fs24 fw400 fc999 ml16">
                {{ info.sidongInfo.job }}
              </div>
              <div class="owner-tag ml26">
                <name-plate nameplate="案主"></name-plate>
              </div>
            </div>
            <!-- 公司 -->
            <div class="mt8 dfr cross-center flex-wrap">
              <div class="fs24 fw400 fc666">
                {{ info.sidongInfo.company }}
              </div>
            </div>
            <!-- 公司行业 公司规模 -->
            <div class="mt4 dfr cross-center flex-wrap">
              <div class="fs24 fw400 fcb2">
                {{ info.sidongInfo.industry.name }}
                {{ info.sidongInfo.scale.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 基本信息 -->
    <div class="bgfff pl36 pr36 pt36">
      <app-title title="基本信息"></app-title>
      <div class="item-cell dfr cross-center">
        <div class="item-left host">主持人</div>
        <div class="item-right avatar-box w100 fc333 dfr cross-center ">
          <div class="moderator-avatar-box dfr cross-center">
            <div class="avatar">
              <img
                :src="info.moderatorInfo.avatar"
                class="w100 h100 object-cover"
                alt=""
              />
            </div>
            <div class="name fs28 fc333">{{ info.moderatorInfo.name }}</div>
          </div>
          <div class="moderator-box ">
            <name-plate :nameplate="info.moderatorInfo.level_name"></name-plate>
          </div>
        </div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">所属领域</div>
        <div class="item-right">{{ info.fieldValue }}</div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">会议日期</div>
        <div class="item-right">{{ info.dateValue }}</div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">会议时间</div>
        <div class="item-right dfr cross-center">
          <div v-if="info.timeValue == '09:00-12:00'">上午</div>
          <div v-if="info.timeValue == '14:00-17:00'">下午</div>
          <div v-if="info.timeValue == '19:00-21:00'">晚上</div>
          ({{ info.timeValue }})
        </div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">会议召开形式</div>
        <div class="item-right">{{ info.meetingMethods }}</div>
      </div>
      <div class="item-cell">
        <!-- meetingMethodsType 1:线下 2:线上 -->
        <div v-if="info.meetingMethodsType == 2" class="dfr cross-center">
          <div class="item-left">
            {{ info.onlineName }}
          </div>
          <div class="item-right">{{ info.onlineRoom }}</div>
        </div>
        <div v-else class="dfr cross-center">
          <!-- offlinePlaceType 1:使用平台推荐的场所 2:使用自己的场所 -->
          <div v-if="info.offlinePlaceType == 2" class="dfr cross-start">
            <div class="item-left">场所地址</div>
            <div class="item-right flex1">{{ info.offlineOwnAddress }}</div>
          </div>
          <div v-else class="dfc">
            <div class="dfr cross-start">
              <div class="item-left">场所名称</div>
              <div class="item-right flex1">{{ info.placeName }}</div>
            </div>
            <div class="dfr cross-start mt28">
              <div class="item-left">场所地址</div>
              <div class="item-right flex1">
                {{ info.placeAddress }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-cell dfr cross-center">
        <div class="item-left">参会私董人数</div>
        <div class="item-right">{{ info.joinNum }}</div>
      </div>
      <!-- 总会议费用 -->
      <div class="price-box por">
        <div class="item-price dfr cross-center main-between">
          <div class="p-l fs28">
            <span class="fc333">总会议费用</span>
            <span class=" fcbbb">(私董会积分)</span>
          </div>
          <div class="p-r">
            <!-- <span class="fs28 fctheme">￥</span> -->
            <span class="fs40 fctheme fw500">{{
              needPrice | formatCurrency
            }}</span>
          </div>
        </div>
        <div class="center-price-item item-price dfr cross-center main-between">
          <div class="p-l fs24 fcbbb">
            主持人费用
          </div>
          <div class="p-r fs24 fc333">
            <!-- ￥{{ info.moderatorInfo.price | formatCurrency }} -->
            {{ info.moderatorInfo.price | formatCurrency }}
          </div>
        </div>
        <div class="item-price dfr cross-center main-between">
          <div class="p-l fs24 fcbbb">
            私董费用 ( {{ SingleSidongPrice }} x {{ info.joinNum }} )
          </div>
          <!-- <div class="p-l fs24 fcbbb">私董费用</div> -->
          <div class="p-r fs24 fc333">
            {{ (SingleSidongPrice * info.joinNum) | formatCurrency }}
          </div>
        </div>
      </div>
    </div>

    <!-- 会议的讨论议题 -->
    <div class="bgfff pl36 pr36 pt36 pb40 mt20">
      <app-title title="会议的讨论议题"></app-title>
      <!-- 我的困惑是，我如何 -->
      <div class="cell-box ">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          我的困惑是，我如何
        </div>
        <div class="inner fs28 dfr  cross-center main-between fc666">
          {{ info.q1 }}
        </div>
      </div>
      <!-- 这个问题是重要的，因为 -->
      <div class="cell-box ">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          这个问题是重要的，因为
        </div>
        <div class="inner fs28 dfr cross-center main-between fc666">
          {{ info.q2 }}
        </div>
      </div>
      <!-- 到目前为止，为了解决这个问题我已经做了 -->
      <div class="cell-box ">
        <div class="cell-title fs28 fb fc333 dfr cross-center">
          <div class="t"></div>
          到目前为止，为了解决这个问题我已经做了
        </div>
        <div class="inner fs28 fc666 dfr cross-center main-between">
          {{ info.q3 }}
        </div>
      </div>
      <!-- 我希望小组帮助我的是 -->
      <div class="cell-box ">
        <div class="cell-title fb fs28 fc333 dfr cross-center">
          <div class="t"></div>
          我希望小组帮助我的是
        </div>
        <div class="inner fs28 dfr cross-center main-between fc666">
          {{ info.q4 }}
        </div>
      </div>

      <app-title
        v-if="info.uploadImgs.length"
        class="mt80"
        title="图片附件"
      ></app-title>
      <!-- 图片附件 -->
      <div class="uploader-box dfr  flex-wrap">
        <div
          v-for="(item, index) in info.uploadImgs"
          :key="index"
          class="img-box"
        >
          <img :src="item" class="w100 h100 object-cover" alt="" />
        </div>
      </div>
    </div>

    <div class="b-button-box">
      <div class="w100 bgfff recruiting-button">
        <div class="dfr cross-center main-between">
          <icy-button
            width="3.24rem"
            height=".8rem"
            background="#fff"
            border="1px solid #CB9025"
            color="#CB9025"
            @click.native="goBack"
            borderRadius=".44rem"
            >返回修改</icy-button
          >
          <icy-button
            v-if="isReplace"
            width="3.24rem"
            height=".8rem"
            @click.native="createMeeting"
            >点击创建</icy-button
          ><icy-button
            v-else
            width="3.24rem"
            height=".8rem"
            @click.native="showPayPopup"
            >支付费用</icy-button
          >
        </div>
      </div>
    </div>

    <!-- 支付框 -->
    <van-popup
      :close-on-click-overlay="false"
      v-model="payPopup"
      class="pay-popup"
    >
      <div class="content-box dfc flex-center">
        <div class="dialog-box dfc flex-center">
          <div class="popup-title fs40 fc333 fb">支付会议费用</div>
          <div class="popup-content p-f w100 dfr cross-center main-between">
            <div class="popup-l fs28 fc333">需要额外购买</div>
            <div class="popup-r fs40 fctheme">{{ otherBuyPrice }}</div>
          </div>
          <div
            class="popup-content p-s w100 fs28  dfr cross-center main-between"
          >
            <div class="popup-l  fc999">账户积分余额</div>
            <div class="popup-r  fc333">{{ myPrice }}</div>
          </div>
          <div
            class="popup-content p-t w100 fs28  dfr cross-center main-between"
          >
            <div class="popup-l  fc999">本次需要积分</div>
            <div class="popup-r  fc333">{{ needPrice }}</div>
          </div>
          <div v-if="isInApplet" class="dfr w100 main-between">
            <icy-button
              @click.native="createMeeting"
              :width="`${!isClickPay ? '5.1rem' : '2.4rem'}`"
              height=".8rem"
              >支付 ￥{{
                otherBuyPrice * this.$config.pointRMBRate
              }}</icy-button
            >
            <icy-button
              v-if="isClickPay"
              width="2.4rem"
              height=".8rem"
              @click.native="paySuccess"
              >我已支付</icy-button
            >
          </div>
          <icy-button
            v-else
            @click.native="createMeeting"
            width="5.1rem"
            height=".88rem"
            >支付 ￥{{ otherBuyPrice * this.$config.pointRMBRate }}</icy-button
          >
        </div>
        <div class="close-box" @click="payPopup = false">
          <img class="w100" src="../assets/images/popup-close.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { isWeiXinBrowser, isApplet } from "../utils/util";
import dayjs from "dayjs";
import NP from "number-precision";
export default {
  data() {
    return {
      info: null,
      payPopup: false,
      myPrice: "",
      needPrice: "",
      otherBuyPrice: "",
      SingleSidongPrice: 0,

      detail: {
        avatar:
          "https://tse1-mm.cn.bing.net/th/id/R-C.d7846ebe1f881f5c97bd8ac2d6b45788?rik=WyuFGg0kQKib3w&riu=http%3a%2f%2ftupian.qqw21.com%2farticle%2fUploadPic%2f2018-4%2f201841922423846502.jpg&ehk=0KA2GAQBll73pXuyLtKmycujYX915bTedaubL5iBRWU%3d&risl=&pid=ImgRaw&r=0",
        name: "张玫君",
        job: "CEO",
        level_name: "案主",
        Company: "上海至尊网络科技有限公司",
        Domain: "IT/TMT/互联网+",
        Scale: "50人以下"
      },
      isReplace: 0,
      isInApplet: false,
      meetingId: "",
      isClickPay: false
    };
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    async initPage() {
      this.isInApplet = isApplet();
      this.isReplace = parseInt(this.$route.query.isReplace) || 0;

      window.scrollTo(0, 0);
      this.$title("会议信息确认");
      this.info = this.$localStore.get("meetingData");

      await this.getSingleSidongPrice();
      this.getUserInfo();

      if (!this.info) {
        this.$go("createMeeting");
      }
    },
    async getUserInfo() {
      const resp = await this.$API.get("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        if (this.info.moderatorInfo.price == 0) {
          this.needPrice = 0;
        } else {
          this.needPrice = NP.plus(
            NP.times(this.SingleSidongPrice, this.info.joinNum),
            this.info.moderatorInfo.price
          );
        }
        this.myPrice = parseFloat(resp.data.info.balance);
        if (this.myPrice >= this.needPrice) {
          this.otherBuyPrice = 0;
        } else {
          this.otherBuyPrice = Math.abs(NP.minus(this.needPrice, this.myPrice));
        }
      }
    },
    // TODO
    async getSingleSidongPrice() {
      const resp = await this.$API
        .post("getSingleSidongPrice", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        // join 私董参加会议的统一价格
        this.SingleSidongPrice = resp.data.join;
      }
    },
    showPayPopup() {
      this.payPopup = true;
    },
    goBack() {
      this.$back();
    },
    // payAndCreateMeeting => createMeeting
    async createMeeting() {
      this.isClickPay = true;
      const _this = this;
      const timeTypes = {
        "09:00-12:00": 0,
        "14:00-17:00": 1,
        "19:00-21:00": 2
      };
      if (!this.info.fieldValue) {
        this.$toast("请选择所属领域");
        return;
      }
      if (!this.info.beDate) {
        this.$toast("请选择会议日期");
        return;
      }
      if (this.info.currentTime === "") {
        this.$toast("请选择会议时间");
        return;
      }
      if (!this.info.moderatorId) {
        this.$toast("请选择主持人");
        return;
      }
      if (!this.info.joinNum) {
        this.$toast("请输入参会私董人数");
        return;
      }
      let is_online = this.info.meetingMethodsType; // 会议形式 1:线下 2:线上
      let meeting_space_type = this.info.offlinePlaceType; // 1:使用平台推荐的场所 2:使用自己的场所
      const data = {
        is_replace: this.isReplace,
        fieldId: this.info.fieldId,
        holdDate: dayjs(this.info.beDate).format("YYYY-MM-DD"),
        holdTimeType: timeTypes[this.info.currentTime],
        hostUserId: this.info.moderatorId,
        questions: [this.info.q1, this.info.q2, this.info.q3, this.info.q4],
        files: this.info.uploadImgs,
        joinNum: this.info.joinNum,
        is_online
      };
      if (is_online == 2) {
        data.room_no = this.info.onlineRoom;
      } else {
        data.meeting_space_type = meeting_space_type;
        if (meeting_space_type == 2) {
          data.userself_address = this.info.offlineOwnAddress;
        } else {
          data.meeting_space_id = this.info.placeId;
        }
      }
      if (this.isReplace) {
        data.user_id = this.info.sidongInfo.id;
      }
      let api = "createMeeting";
      if (this.isInApplet) {
        api = "appletCreateMeeting";
      }
      const dotPayHandle = () => {
        this.$toast.success("创建成功");
        this.$localStore.remove("meetingData");
        this.$goReplace(`me`);
      };
      const resp = await this.$API.post(api, data).then(resp => resp);
      console.log("resp: ", resp);

      if (resp.code == 1000) {
        if (this.isReplace) {
          // 创建成功 跳转到创建成功页面
          _this.$localStore.remove("meetingData");
          _this.$goReplace(`successCreate`, {
            meetingId: resp.data.meeting.id
          });
        } else {
          // 先判断 在小程序还是其他
          if (isApplet()) {
            console.log("resp.data.needPay: ", resp.data.needPay);
            if (resp.data.needPay) {
              //点击微信支付后，调取统一下单接口生成微信小程序支付需要的支付参数
              const paymentData = {
                meetingId: resp.data.meeting.id,
                amount: this.needPrice,
                myPrice: this.myPrice,
                wechatPay: this.otherBuyPrice * this.$config.pointRMBRate
              };
              this.meetingId = resp.data.meeting.id;
              console.log("paymentData: ", paymentData);

              //定义path 与小程序的支付页面的路径相对应
              const path =
                "/pages/payment/payment?payParam=" +
                encodeURIComponent(JSON.stringify(paymentData));
              //通过JSSDK的api跳转到指定的小程序页面
              window.wx.miniProgram.navigateTo({ url: path });
            } else {
              dotPayHandle();
            }

            // h5支付相关
          } else {
            //判断是否为微信内置浏览器
            if (resp.data.payment) {
              if (isWeiXinBrowser()) {
                let config = resp.data.payment;
                window.WeixinJSBridge.invoke(
                  "getBrandWCPayRequest",
                  {
                    appId: config["appId"], //公众号ID，由商户传入
                    timeStamp: config["timeStamp"],
                    nonceStr: config["nonceStr"],
                    package: config["package"],
                    signType: config["signType"],
                    paySign: config["paySign"] // 支付签名
                  },
                  function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      _this.$toast.success("支付成功");
                      _this.$localStore.remove("meetingData");
                      _this.$goReplace(`me`);
                    }
                  }
                );
              } else {
                // TODO 浏览器支付
                this.$toast("请在微信中打开");

                // const domain = this.$mainURL.payDomain;
                // const payUrl = this.$Base64.encode(resp.mweb_url);
                // // const payUrl = resp.mweb_url;
                // const wechatPayHref = `${domain}/#/wechatPayPage?payUrl=${payUrl}`;
                // location.href = wechatPayHref;
              }
            } else {
              dotPayHandle();
            }
          }
        }
      }
    },
    goDetail(id) {
      this.$go("detailS", { id: id });
    },
    paySuccess() {
      this.isClickPay = false;
      this.payPopup = false;
      if (this.meetingId) {
        this.$goReplace("myMeeting", { type: 0 });
      } else {
        this.initPage();
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.caseOwner {
  padding: 0.36rem;
  .item-inner-box {
    width: 6.94rem;
    background: #ffffff;
    margin-top: 0.28rem;
    border-radius: 0.12rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  .item-portrait-box {
    width: 0.96rem;
    height: 0.96rem;
    position: relative;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
    .item-badge-box {
      width: 0.28rem;
      height: 0.28rem;
      position: absolute;
      right: 0;
      bottom: 0;
      img {
        position: absolute;
        object-fit: cover;
      }
    }
  }
  .item-message {
    margin-left: 0.2rem;
  }
  .owner-tag {
    transform: scale(1.2);
    transform-origin: right center;
  }
  .item-delete {
    width: 0.28rem;
    height: 0.28rem;
    margin-right: 0.36rem;
    img {
      object-fit: cover;
    }
  }

  .field-item-box {
    margin: 0.24rem 0.36rem 0.36rem 0.36rem;
    .field-item {
      background: #f6f6f6;
      padding: 0.08rem 0.16rem;
      margin: 0.12rem 0.12rem 0 0;
    }
  }
}
.create-meeting {
  width: 7.5rem;
  background: #f7f7f7;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;

  .price-box {
    margin-top: 0.4rem;
    border-top: 0.01rem solid #eee;
    padding: 0.48rem 0 0.68rem;
    .center-price-item {
      margin: 0.48rem 0 0.16rem;
    }
  }
  .cell-box {
    padding: 0 0 0.16rem;
    margin-top: 0.36rem;
    .inner {
      min-height: 0.4rem;
      margin-top: 0.2rem;
      padding-left: 0.26rem;
      .placeholder {
        color: #cccccc;
      }
    }
  }
  .bottom-btn {
    height: 1.2rem;
  }
  .uploader-box {
    margin-top: 0.36rem;
    .img-box {
      width: 2.1rem;
      height: 2.1rem;
      border-radius: 0.08rem;
      overflow: hidden;
      margin: 0 0.22rem 0.22rem 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.pay-popup {
  background: transparent;
  .dialog-box {
    width: 6.3rem;
    height: 5.88rem;
    background: #ffffff;
    border-radius: 0.24rem;
    padding: 0 0.6rem;
    .popup-content {
      &.p-f {
        margin: 0.68rem 0 0.36rem;
      }
      &.p-t {
        margin: 0.2rem 0 0.8rem;
      }
    }
  }
  .close-box {
    width: 0.72rem;
    height: 0.74rem;
    margin-top: 0.8rem;
  }
}
.item-cell {
  font-size: 0.28rem;
  color: #666;
  margin-top: 0.28rem;
  .item-left {
    margin-right: 0.2rem;
    &.host {
      width: 5em;
    }
  }
  .item-right {
    color: #333;
    .moderator-avatar-box {
      margin-right: 0.4rem;
    }
  }
}
.line {
  height: 0.01rem;
  width: 100%;
}
.bottom {
  height: 1.2rem;
  .bottom-btn-box {
    width: 7.5rem;
    height: 1.2rem;
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0 0.36rem;
  }
}
.t {
  width: 0.12rem;
  height: 0.12rem;
  background: #333;
  transform: rotate(45deg);
  margin-right: 0.16rem;
}
.moderator-avatar-box {
  .avatar {
    width: 0.48rem;
    height: 0.48rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.12rem;
  }
}
.moderator-box {
  transform: scale(1.1);
  transform-origin: right center;
}

.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
